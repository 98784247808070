import Footer from "../Components/Footer/Footer";
import Head from "../Components/Head/Head";
import Header from "../Components/Header/Header";
import './Diagnostic.css';

const Diagnostic = () => {            
    return (<>
        <Header isLanding={false}/>
        <Head pageName={"diagnostic"}/>
        <Footer/>
    </>
)
};

export default Diagnostic;