import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import './Privacy.css';

const Privacy = () => {
    return (<> <Header isLanding={false}/>
            <section id="Privacy">
                <div className="title">Privacy Policy</div>

                <div className="impotant_tag">Effective Date: 1st February, 2024</div>
                
                <h1>1. Introduction</h1>    
                <div>
                    Welcome to Marvyn. Your privacy and trust are important to us. This Privacy Policy explains how we handle your personal data in compliance with the General Data Protection Regulation (GDPR).
                </div>

                <h1>2. Contact Details</h1>
                <div>
                    Company Name: Marvyn <br/>
                    Address: 71 rue de Roquebilière, 06300 Nice <br/>
                    Email: hello@marvyn.app <br/>
                    Website: https://marvyn.app/ <br/>
                    Data Protection Officer: Jeremy Maisse (jeremy@marvyn.app) <br/>
                </div>

                <h1>3. Data Collection and Usage</h1>
                <div>
                    We collect:
                    <ul>
                        <li>Email addresses, potentially your name, company name, and browsing behavior.</li>
                        <li>Documents with confidential information (e.g., pitch decks, business plans).</li>
                    </ul>
                    Purpose of Data Use to improve our services, manage customer support, perform marketing, and conduct analytics.<br/>
                    Data Collection Methods: Through online forms, customer interactions, cookies, and API calls.
                </div>

                <h1>4. Legal Basis for Processing</h1>
                <div>
                    Each data processing activity has a clear legal basis:
                    <ul>
                        <li>Consent: For marketing and analytics, based on your explicit consent.</li>
                        <li>Contractual Necessity: For providing services and customer support.</li>
                        <li>Legal Obligation: If we're required to process data by law.</li>
                        <li>Legitimate Interests: For internal business processes not overridden by your rights.</li>
                    </ul>
                </div>

                <h1>5. Data Sharing and Disclosure</h1>
                <div>
                    We maintain strict control over data sharing: No data is shared with third parties without a separate, explicit agreement.
                </div>

                <h1>6. Data Storage, Security, and Retention</h1>
                <div>
                    <ul>
                        <li>Data Storage: Stored in a GDPR-compliant, secure database in Europe.</li>
                        <li>Data Security: Protected with encryption and access controls. Regular security audits to ensure data integrity.</li>
                        <li>Personal data Retention Periods: 2 years without modification.</li>
                        <li>Analyzed documents Retention Periods: 5 years, based on business needs.</li>
                        <li>User behavior data Retention Periods: 1 year for service improvement.</li>
                    </ul>
                </div>

                <h1>7. Data Transfers Outside the EU</h1>
                <div>We do not transfer personal data outside of Europe, ensuring data remains under GDPR protection.</div>

                <h1>8. Your Rights</h1>
                <div>
                    You have comprehensive rights, including:
                    <ul>
                        <li>Access: To view and obtain a copy of your data.</li>
                        <li>Rectification: To correct inaccurate data.</li>
                        <li>Erasure: To delete your data, where applicable.</li>
                        <li>Data Portability: To transfer your data to another service.</li>
                        <li>Object: To processing under certain conditions.</li>
                        <li>Automated Decisions: You have rights concerning automated decision-making, which we do not currently employ.</li>
                    </ul>
                </div>

                <h1>9. Consent and Withdrawal of Consent</h1>
                <div>
                    Consent is given actively when using our services.<br/>
                    Withdraw consent anytime via email: hello@marvyn.app.
                </div>

                <h1>10. Information about Complaints</h1>
                <div>
                    To lodge a complaint, contact your local data protection authority. Links and contact details can be found on our website.
                </div>

                <h1>11. Data Breach Notification</h1>
                <div>
                    In case of a data breach, affected individuals will be notified via email immediately.<br/>
                    Legal actions will be taken promptly to address the breach.
                </div>

                <h1>12. Changes to this Policy</h1>
                <div>Policy updates will be communicated via email at least 30 days prior, unless urgent changes are required.</div>

                <h1>13. Contact Us</h1>
                <div>For queries or concerns regarding this policy or your data: hello@marvyn.app</div>
            </section>
            <Footer/>
        </>
    )
};

export default Privacy;