import './Head.css';
import Dropzone from '../Dropzone/Dropzone';

const Head = (props: { pageName: string; }) => {
    return (<section id='head'>
            <div className='head-titles'>
                <h1 className='first-title'>Meet your new</h1>
                <h1 className='secund-title'>VC Analyst</h1>
            </div>
            <Dropzone pageName={props.pageName}/>
        </section>)
};

export default Head;