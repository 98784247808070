import './Faq.css';
import { Accordion, Container, Badge } from '@mantine/core';

const Faq = () => {
    const QRS = [{id: 1, question: "How does Marvyn evaluate pitch decks and business plans?", response:"We utilize advanced natural language processing (NLP) algorithms to assess pitch decks and business plans comprehensively. Our analysis includes factors such as market research, financial projections, team qualifications, and overall clarity."},
                 {id: 2, question: "Is data sent to Marvyn secured?", response:"Data security is paramount. Your documents are stored on secure servers that comply with European GDPR standards. We retain these documents temporary and solely to improve our services, ensuring the utmost confidentiality and protection of your data."},
                 {id: 3, question: "How scalable is Marvyn's technology?", response:"Marvyn's technology is highly scalable and can handle a growing volume of deals without compromising analysis quality or response time. It seamlessly integrates with various VC operations."},
                 {id: 4, question: "Can Marvyn provide personalized analysis?", response:"Personalization depends on your investment thesis. While we aim to offer customization options for VC preferences and criteria, the extent of personalization may vary based on your specific needs."},
                 {id: 5, question: "What's on Marvyn's roadmap?", response:" Our roadmap includes a focus on more extensive web research and market analysis. We plan to enhance our AI capabilities for deeper due diligence, integrate with additional VC tools, and refine our services based on user feedback."}]
    
    return (
        <section id="faq">
            <Container>
                <div style={{ marginBottom: 30 }}>
                    <Badge variant="filled">FREQUENTLY ASKED QUESTIONS</Badge>
                    <div className="faq-title">We answer your questions</div>
                </div>

                <Accordion variant="contained">
                    {QRS.map(QR => <Accordion.Item value={QR.id.toString()} key={QR.id}>
                                        <Accordion.Control> 
                                            {QR.question}
                                        </Accordion.Control>
                                        <Accordion.Panel>{QR.response}</Accordion.Panel>
                                    </Accordion.Item>)}
                </Accordion>
            </Container> 
        </section>
    )
};

export default Faq;