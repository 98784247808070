import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from './Views/Landing';
import Privacy from './Views/Privacy';
import Synthese from './Views/Synthese';
import Diagnostic from './Views/Diagnostic';
import Terms from './Views/Terms';
import { createRoot } from 'react-dom/client';
import 'font-awesome/css/font-awesome.min.css';

const domNode = document.getElementById('root');

if(domNode) {
  const root = createRoot(domNode);
  root.render( <BrowserRouter>
    <Routes>  
      <Route path="/" element={<Landing/>} />
      <Route path="/Privacy" element={<Privacy/>} />
      <Route path="/Synthese" element={<Synthese/>} />
      <Route path="/Terms" element={<Terms/>} />
      <Route path="/Diagnostic" element={<Diagnostic/>} />
    </Routes>
  </BrowserRouter> );
}