import Footer from "../Components/Footer/Footer";
import Head from "../Components/Head/Head";
import Header from "../Components/Header/Header";
import './Synthese.css';

const Synthese = () => {            
    return (<>
        <Header isLanding={false}/>
        <Head pageName={"synthese"}/>
        <Footer/>
    </>
)
};

export default Synthese;