import './Header.css';

const Header = (props: { isLanding: any; }) => {
    return (
        <header id="top-of-page">
            <div className="header-desktop header-content">
                <img className="badge-logo" src={process.env.PUBLIC_URL + '/Assets/Images/Logo.png'} alt="oops"/>
                {props.isLanding ? <div className="navbar">
                    <a className="navbar-item" href="#about">Features</a>
                    <a className="navbar-item" href="#founder">Founder</a>
                    <a className="navbar-item" href="#faq">FAQ</a>
                </div> : <></>}
                <a className="navbar-button" href="https://calendly.com/grm-83600/vc-interview">Book a call</a>
            </div>
        </header>
    )
};

export default Header;
