import { useEffect, useState } from 'react';
import './Dropzone.css';

const Dropzone = (props: { pageName: string; }) => {
    const pageName = props.pageName;

    const [isOk, setIsOk] = useState<boolean>(true);
    const [pdfFile, setPdfFile] = useState<File>();
    const [csvFile, setCsvFile] = useState<File>(); 
    const [stepForm, setStep] = useState<number>(0);
    const [remaining, setRemaining] = useState<number>(-1);
    const [url, setUrl] = useState<string>('');
    const [infoStorage, setInfoStorage] = useState<string>(localStorage.getItem(pageName === 'landing' ? "mailStorageMarvyn" : "apiKeyStorageMarvyn" ) || "");
    const [errorMessage, setErrorMessage] = useState<string>("Oops...Something went wrong !");

    const sendPostRequest = async (info : string) => {
        if(!info) return; 
        try {
            const apiLink = 'https://marvyn.ew.r.appspot.com/' + (pageName === 'landing' ? 'startup' : pageName === 'synthese' ?'synthesis' : 'diagnostic');
            const formData = new FormData();

            // formData.append("debug", "default");
            // formData.append("debug", "error");

            if(pdfFile) formData.append(pageName === 'landing' ? "files" : 'pitch_deck', pdfFile);
            if(pageName === 'landing' && csvFile) formData.append("files", csvFile);

            localStorage.setItem(pageName === 'landing' ? "mailStorageMarvyn" : "apiKeyStorageMarvyn", info )
            setInfoStorage(localStorage.getItem(pageName === 'landing' ? "mailStorageMarvyn" : "apiKeyStorageMarvyn" ) || "");

            if(pageName === 'landing') {
                formData.append("email", info);
                formData.append("language",window.navigator.language);
            }
            
            const header = new Headers();
            if (pageName !== 'landing') header.append("Authorization", 'Bearer ' + info);

            let options = { headers: header, method: 'POST', body: formData};

            const response = await fetch(apiLink, options);
            const json = await response.json();

            if(!json || json.error) {
                setStep(3);
                setErrorMessage('Oops... ' + json.error);
                setIsOk(false);
                return;
            }

            if(pageName === 'landing') setRemaining(json?.remaining_demo_tries ?? -1);
            else setUrl(json?.url);
            setStep(3);

        } catch (error) {
            setStep(3);
            setErrorMessage('Erreur lors de la requête POST :' + error);
            setIsOk(false);
        }
    };

    const buttonClick = () => {
        if (stepForm===3 && !isOk) {
            setIsOk(true);
            setStep(0);
            setPdfFile(undefined); 
            setCsvFile(undefined);
        }
        else if(pageName !== 'landing' && stepForm===3) window.open(url, '_blank');
        else if(stepForm===0) {
            setStep(1);
        }
        else if(stepForm===1) {
            const inputValue = (document.getElementById("stepForm_2_info") as HTMLInputElement);
            if(pageName === 'landing') {
                const isEmailValid = inputValue.checkValidity();
                if(!isEmailValid) return;
            }
            setStep(2);
            sendPostRequest(inputValue.value);
        }
    }
    
    const inputFocus = () => {
        var formLabel = document.getElementById("formLabel") as HTMLInputElement;
        formLabel.className = 'formTop';
    }

    const inputUnFocus = () => {
        var formInputs = document.getElementById("stepForm_2_info") as HTMLInputElement;
        var formLabel = document.getElementById("formLabel") as HTMLInputElement;
        if (formInputs.value.length === 0){
            formLabel.className = '';
        }
    }

    useEffect(() => {
        if( infoStorage){
            document.getElementById("dropZone")?.addEventListener('DOMNodeInserted',  function(e) {
                const id = (e?.target as HTMLInputElement)?.id
                if(id === "infoContainer") inputFocus();
            });
        }
        const pdfZone = document.getElementById("pdf-zone");
        const pdfInput = document.getElementById("drop-pdf-input") as HTMLInputElement;
        const csvZone = document.getElementById("csv-zone");
        const csvInput = document.getElementById("drop-csv-input") as HTMLInputElement;

        if(!pdfZone || !pdfInput || (pageName === 'landing' && (!csvZone || !csvInput)) ) return;
      
        const onDraggedOver = (e : Event, htmlElement : HTMLElement, isPresent: boolean) =>{
            e.preventDefault();
            if(isPresent) htmlElement.classList.remove("dragged-over");
            else htmlElement.classList.add("dragged-over");
        };

        pdfInput.addEventListener("change", () => handleFiles(pdfInput.files));
        pdfZone.addEventListener("dragover", (e) => {onDraggedOver(e,pdfZone,false);});
        pdfZone.addEventListener("dragleave", (e) => {onDraggedOver(e,pdfZone,true);});
        pdfZone.addEventListener("drop", (e) => { onDraggedOver(e,pdfZone,true); handleFiles( e.dataTransfer?.files ?? null);});
        pdfZone.addEventListener("click", () => { pdfInput.click();});
        
        if(csvZone && pageName === 'landing') {
            csvInput.addEventListener("change", () => handleFiles(csvInput.files));
            csvZone.addEventListener("dragover", (e) => {onDraggedOver(e,csvZone,false);});
            csvZone.addEventListener("dragleave", (e) => {onDraggedOver(e,csvZone,true);});
            csvZone.addEventListener("drop", (e) => {onDraggedOver(e,csvZone,true); handleFiles( e.dataTransfer?.files ?? null);});
            csvZone.addEventListener("click", () => { csvInput.click();});
        }
  
    });

    function handleFiles(files: FileList | null) {
        if (!files) return;
        let wrongFiles = 0;

        Array.from(files).forEach(file => { 
            const extension = file.type.split('/')[1];

            if (extension === 'pdf' && file.size !==0) setPdfFile(file);
            else if (extension === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.size !==0) setCsvFile(file);
            else wrongFiles++;
        });

        if(wrongFiles > 0 ) alert("be careful marvyn only supports pdf and xlsx of a size greater than 0");
    }
  
    return (<section id="dropZone">
                <img className="robot" src={process.env.PUBLIC_URL + '/Assets/Images/Robot.png'} alt="oops"/>
                {stepForm===0 ? (<>
                    <h2>{pageName === 'landing' ? 'Upload for a quick demo analysis.' : pageName === 'synthese' ? 'Upload to synthesize' : 'Upload to diagnose'}</h2>
                    <span className="second-title">Drag and drop here or click to upload button</span>
                    <div className='drop-list'>
                        <div id="pdf-zone" className='drop-zone'>
                            <input id='drop-pdf-input' type='file' multiple={true} className='drop-zone-input'/>
                            <div className="drop-zone-icons"> <i className={pdfFile ? "fa fa-file-pdf-o" : "fa fa-cloud-upload"}/> </div>
                            <span className="drop-zone-text">{pdfFile?.name ?? 'Pitch Deck (pdf)'}</span>
                        </div>
                        {pageName === 'landing' &&
                        <div id="csv-zone" className='drop-zone'>
                            <input id='drop-csv-input' type='file' multiple={true} className='drop-zone-input'/>
                            <div className="drop-zone-icons"> <i className={csvFile ? "fa fa-table" : "fa fa-cloud-upload"}/></div>
                            <span className="drop-zone-text">{csvFile?.name ?? 'Business Plan (xlsx)'}</span>
                        </div>}
                    </div></>) : stepForm===1 ? <>
                        <h2>{pageName === 'landing' ? 'Enter your email to receive results' : 'Enter your API key'}</h2>
                        <div className="form-item" id="infoContainer">
                            <p id="formLabel">{pageName === 'landing' ? 'Email' : 'API_Key' }</p>
                            <input onFocus={inputFocus} onBlur={inputUnFocus} type={pageName === 'landing' ? "email" : 'text'} name="stepForm_2_info" id="stepForm_2_info" className="form-style" value={infoStorage} onChange={(e) => setInfoStorage(e.target.value)} required/>
                        </div>
                    </> : stepForm===2 ? <>
                        <h2>{pageName === 'landing' ? 'Uploading '  : pageName === 'synthese' ? 'Synthesizing ' : 'Diagnosing '} files...</h2>
                        <div className="loader"/>
                    </> : stepForm === 3 && isOk && !(pageName === 'landing' && remaining===-1) ?
                        <h2>{pageName === 'landing'  ? "The upload is over you will soon receive an email with the analyse !" : 'Your ' +( pageName === 'synthese' ? 'document' : 'diagnostic' )+' is ready! '}</h2>
                        :<h2>{errorMessage}</h2>
                    }    
                
                {/* bouton stylé */}
                {(!isOk && stepForm ===3) || ((csvFile || pdfFile) && (stepForm===0 || stepForm===1 || (stepForm===3 && pageName !== 'landing') )) ? (<div className="drop-zone-next-button-container"><svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                        <filter id="bubble-filter">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                            <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="highContrastGraphic" />
                            <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
                        </filter>
                    </defs>
                </svg>
                <button id="drop-zone-next-button" onClick={buttonClick}> 
                    {stepForm===3 && !isOk ? 'Try again' : stepForm===3 && pageName !== 'landing'  ? 'Download' : 'Next'}
                    <span className="bubbles">
                        {Array.from({ length: 9 }, () => ( <span className="bubble" /> ))}
                    </span>
                </button></div>) : <></>}

            </section>)
};

export default Dropzone;