import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import './Terms.css'

const Terms = () => {
    return (<><Header isLanding={false}/><section id="Terms">
                <div className="title">Terms of Service</div>

                <h1>Introduction</h1>
                <div> Welcome to Marvyn, an AI-driven SaaS providing automated analysis of pitch decks and business plans. By using Marvyn, you agree to these Terms of Service. </div>
                
                <h1> Service Description</h1>
                <div> Marvyn specializes in analyzing pitch deck (PDF) and business plan (Excel) documents for venture capital funds. The service is accessible via our website demo or API calls. Note that our AI-generated analysis may contain inaccuracies; users should use this information as a guide rather than a definitive source. </div>
                
                <h1>User Registration and Account Management</h1>
                <div> Users must register to access Marvyn's full capabilities. Keep your account details secure and notify us immediately of any unauthorized use of your account. </div>
                
                <h1> User Responsibilities and Conduct</h1>
                <div>Users are responsible for ensuring the legality of data shared with Marvyn. Marvyn is not liable for any confidential data received. Users should independently verify the information provided by Marvyn before making decisions.</div>
    
                <h1>Data Handling and Privacy</h1>
                <div>Marvyn processes and stores user data in accordance with our Privacy Policy, which outlines our data handling practices and compliance with data protection laws.</div>
                
                <h1>Intellectual Property Rights</h1>
                <div>Marvyn retains all rights to its software, technology, and any content on the platform. Users are prohibited from infringing upon or misusing Marvyn's intellectual property.</div>
                
                <h1>Content Ownership and Usage Rights</h1>
                <div>All user-generated content on Marvyn is owned by Marvyn. Users are permitted to share this content within their organization, citing Marvyn as the source. Commercial use or external sharing is prohibited.</div>

                <h1>Service Availability and Limitations</h1>
                <div>Marvyn is provided on an "as is" basis. We do not guarantee that the service will be uninterrupted or error-free. We are not liable for any inaccuracies in the information provided.</div>

                <h1>Modification of Terms and Service</h1>
                <div>Marvyn reserves the right to modify these Terms and the services offered at any time. Users are responsible for regularly reviewing the Terms. Continued use of the service signifies acceptance of any changes.</div>

                <h1>Termination</h1>
                <div>Users may terminate their use of Marvyn at any time. Marvyn also reserves the right to suspend or terminate access to the service under certain conditions.</div>

                <h1>Governing Law and Dispute Resolution</h1>
                <div>These Terms are governed by the laws of France. Any disputes arising from these Terms will be subject to the exclusive jurisdiction of the courts in Nice, France.</div>

                <h1>Contact Information</h1>
                <div>For any inquiries or support needs, please contact us at hello@marvyn.app.</div>

            </section><Footer/></>
    )
};

export default Terms;