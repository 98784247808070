import './TextImage.css'

const TextImage = (props : {id:string, imageName: string, title:string, tag:string, description:string, direction:boolean, link:string, linkName:string}) => {
    return (
        <>
        <section className={'aboutSection ' + (props.direction ? 'sectionRow' : 'sectionRevertedRow')} id={props.id}>
            <img src={process.env.PUBLIC_URL + '/Assets/Images/' + props.imageName} alt={'oups'} className='textImageImage' />
            <div className={"about-text"}>
                <span className='about-tag'>{props.tag}</span>
                <div className="about-title">{props.title}</div>
                <div className="about-description">{props.description}</div>
                <a className="about-button" href={props.link}>{props.linkName}</a>
            </div>
        </section>
        </>
    )
};

export default TextImage;