import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <footer id='section-footer'>
            <div className="footer-content">
                <h3>Ready to get started?</h3>
                <ul className="socials">
                    <li><a href="https://www.linkedin.com/company/Marvyn"><i className="fa fa-linkedin-square"/></a></li>
                    <li><a href="https://calendly.com/grm-83600/vc-interview"><i className="fa fa-calendar"/></a></li>
                    <li><a href="mailto:hello@marvyn.app?subject=I already love marvyn!"><i className="fa fa-envelope"/></a></li>
                    <li><a href="https://chat.openai.com/g/g-qJFhOniM7-marvyn"><i className="fa fa-commenting"/></a></li>
                </ul>
            </div>
            <div className="footer-bottom">
                <p>copyright &copy; LEGROS Inès</p>
                <img className="footer-logo" src={process.env.PUBLIC_URL + '/Assets/Images/Logo_white.png'} alt="oops"/>
                <div className="footer-menu">
                    <ul>
                        <li><Link to={'/'}>Home</Link></li>
                        <li><Link to={'/Privacy'}>Privacy Policy</Link></li>
                        <li><Link to={'/Terms'}>Terms of Service</Link></li>
                    </ul>
                </div>
            </div>
    
        </footer>)
};

export default Footer;