import './Founder.css';

const Founder = () => {
    return (<section id="founder">
                <div className="founder_content">
                    <div className="founder_text">
                        <p>Founder of Marvyn and multi-entrepreneur, holds an IT engineering degree and an MBA. After establishing Lycie, he now aims to build a 'startup factory' with Marvyn, leveraging AI to identify and nurture future-focused startup opportunities. This represents a novel approach in the startup ecosystem.</p>
                        <h2>Jeremy</h2>
                        <p>Founder</p>
                    </div>
                    <div className="founder_image">
                        <img src={process.env.PUBLIC_URL + '/Assets/Images/Jeremy.jpeg'} alt="avatar"/>
                    </div>
                </div>
            </section>)
};

export default Founder;