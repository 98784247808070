import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Head from "../Components/Head/Head";
import Faq from "../Components/Faq/Faq";
import TextImage from "../Components/TextImage/TextImage";
import Founder from "../Components/Founder/Founder";

const Landing = () => {
    return (<>
                <Header isLanding={true}/>
                <Head pageName={"landing"}/>
                <TextImage 
                    id={"about"}
                    imageName={"B1.png"} 
                    title={"Automated Deal Flow"} 
                    tag={"Unlock Efficiency"}
                    description={"Marvyn revolutionizes your deal flow process with automation. Our AI analyzes pitch decks and business plans, automating key tasks such as screening and due diligence. Say goodbye to manual processes and embrace a streamlined workflow"} 
                    direction={true}
                    link={"https://calendly.com/grm-83600/vc-interview"}
                    linkName={"Automate Your Workflow"} />
                <TextImage 
                    id={"otherAbout"}
                    imageName={"C1.png"} 
                    title={"Seamless CRM Integration"} 
                    tag={"Delegated Data Management"}
                    description={"Marvyn seamlessly syncs with your favorite tools, including Airtable. No need for additional interfaces or complex setups. Your investment data flows effortlessly into your CRM. Experience a new level of efficiency."} 
                    direction={false}
                    link={"https://calendly.com/grm-83600/vc-interview"}
                    linkName={"Connect with my CRM"} />
                <Founder/>
                <Faq/>
                <Footer/>
            </>
    )
};

export default Landing;